import React from 'react'

import { useFormContext } from 'react-hook-form'

import { formatString } from 'utils/formatters'
import { useStores } from 'stores'
import { validateMaxLength, validateMinimum, validateSpecialCharacters } from 'utils/validators' 
import FileAttachment from 'common/Forms/FileAttachment'
import Input from 'common/Forms/Input'
import ReactSelect from 'common/Forms/ReactSelect'
import WebformProductLineInfoQty from 'components/Webform/WebformProductLineInfoQty'

export default ({ authStore, resource, lineItem, index, data }) => {
  const { setValue } = useFormContext()
  const [category, setCategory] = React.useState(lineItem.category)
  const [subCategory, setSubCategory] = React.useState(lineItem.subCategory)
  const [categories, setCategories] = React.useState(data.categories)
  const [subCategories, setSubCategories] = React.useState(data.subcategories || [])
  const [isDisableSubCategory, setIsDisableSubCategory] = React.useState(false)
  const { pageResourceStore } = useStores()

  const {
    AppSession: { session },
  } = authStore
  const { settings = {}, glAccounts = [] } = data
  const {
    PageResource: { common: commonResource = {} },
  } = pageResourceStore

  React.useEffect(() => {
    if (category) {
      handleCategoryChange(category)
    }
  }, [])

  const handleCategoryChange = selected => {
    if (selected) {
      const newSubCategories = data.subcategories.filter(item => item.categoryNames.includes(selected.name))
      if (newSubCategories && newSubCategories.length) {
        setIsDisableSubCategory(false)
      } else {
        setIsDisableSubCategory(true)
        setSubCategory(null)
        setValue(`webformLines.${index}.subCategory`, null)
      }
      setSubCategories(newSubCategories)
    } else {
      setCategories([...data.categories])
      setSubCategories([...data.subcategories])
      setIsDisableSubCategory(false)
      setSubCategory(null)
      setValue(`webformLines.${index}.subCategory`, null)
    }
    setCategory(selected)
  }

  const handleSubCategoryChange = selected => {
    if (selected) {
      const newCategories = data.categories.filter(
        item => item.subcategories && item.subcategories.includes(selected.name)
      )
      setCategories(newCategories)
    } else {
      setCategories([...data.categories])
      setSubCategories([...data.subcategories])
    }
    setSubCategory(selected)
  }

  const isTotalBudgetRequired = !!session.clientController?.wf || settings.isWfBudgetThresholdEnabled
  const isShowExtendedDescriptionPlaceholder = session.clientAbbreviation.startsWith('ELIA')
  const maxDescriptionLength = settings.erp === 'SAP' ? 40 : 150

  return (
    <React.Fragment>
      <ReactSelect
        name={`webformLines.${index}.category`}
        rules={{ required: resource.Required }}
        label={`${resource.Category} *`}
        placeholder={resource.SelectAppropriateCategory}
        options={categories}
        optionValueKey="id"
        optionLabelKey="name"
        formGroup
        horizontal
        isClearable
        onChange={handleCategoryChange}
      />
      {settings.shouldSelectSubcategory && (
        <ReactSelect
          name={`webformLines.${index}.subCategory`}
          rules={{ required: !isDisableSubCategory && !!category && resource.Required }}
          value={!isDisableSubCategory ? subCategory : null}
          label={resource.SubCategory}
          placeholder={resource.SelectAppropriateSubcategory}
          options={subCategories}
          optionValueKey="id"
          optionLabelKey="name"
          formGroup
          horizontal
          isClearable
          isDisabled={isDisableSubCategory}
          onChange={handleSubCategoryChange}
        />
      )}
      {settings.enableGLAccount && (
        <ReactSelect
          name={`webformLines.${index}.glAccount`}
          label={`${resource.GLAccount} *`}
          placeholder={resource.SelectAppropriateGLAccount}
          options={glAccounts}
          optionValueKey="id"
          optionLabelKey="glAccount"
          formGroup
          horizontal
        />
      )}
      <Input
        name={`webformLines.${index}.articleDescription`}
        rules={{
          required: resource.Required,
          validate: {
            specialCharacters: value => {
              return (
                settings.allowSpecialCharacters || validateSpecialCharacters(value, commonResource.SpecialCharacter)
              )
            },
            maxLength: value => {
              return validateMaxLength(
                value,
                maxDescriptionLength,
                formatString(commonResource.MaxLength, maxDescriptionLength)
              )
            },
          },
        }}
        label={`${resource.ProductDescription} *`}
        placeholder={resource.phProductDescription}
        formGroup
        horizontal
      />
      <WebformProductLineInfoQty {...{ commonResource, resource, index }} />
      <Input
        name={`webformLines.${index}.budget.upperLimit`}
        rules={{
          required: isTotalBudgetRequired && commonResource.Number,
          validate: value => {
            return validateMinimum(value, 0, formatString(commonResource.MinValue, 0))
          },
        }}
        min="0"
        type="number"
        label={`${resource.TotalBudget} ${isTotalBudgetRequired ? '*' : ''}`}
        placeholder={resource.phTotalBudget}
        formGroup
        horizontal
        inputCol={{ md: 5 }}
        startAdornment={<span className="material-icons-outlined md-20 text-muted">euro</span>}
      />
      <Input
        name={`webformLines.${index}.supplierPreference`}
        label={resource.PreferredSupplier}
        placeholder={resource.phPreferredSupplier}
        formGroup
        horizontal
      /> 
      <Input
        name={`webformLines.${index}.remarks`}
        type="textarea"
        label={resource.ExtendedDescription}
        {...(isShowExtendedDescriptionPlaceholder
          ? {
              placeholder: resource.phExtendedDescription,
            }
          : {})}
        formGroup
        horizontal
        rows="4"
      />
      <FileAttachment
        name={`webformLines.${index}.attachments`}
        label={resource.Attachments}
        formGroup
        horizontal
        formClassName="mb-4"
        hideNoAttachments
      />
    </React.Fragment>
  )
}
